import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import IconQuote from "../../../images/svg/quote.inline.svg"
import { CarouselProvider, Slider, Slide } from "pure-react-carousel"
import { useCurrentSlideNumber } from "../../../hooks/common"

const TestimonyItem = ({ testimony, title, position, index }) => {
  return (
    <Slide className="w-full lg:w-1/3 px-3 cursor-default" index={index}>
      <div className={`p-8 mb-6 bg-white shadow rounded h-full flex flex-col`}>
        <IconQuote className="text-gold w-full h-12 mb-4" />
        <p className="mt-4 mb-2 leading-loose text-jet-light text-justify flex-1">
          {testimony}
        </p>
        <div>
          <p className="text-xl">{title}</p>
          <p className="text-gold-darker">{position}</p>
        </div>
      </div>
    </Slide>
  )
}

const PaginationIndicator = ({ testimonials }) => {
  const currentSlideNumber = useCurrentSlideNumber()

  return (
    <>
      {testimonials.map(({ title }, index) => (
        <span
          key={title}
          className={`h-2 w-2 rounded-full ${
            currentSlideNumber === index ? "bg-gold" : "bg-jet-lighter"
          }`}
        />
      ))}
    </>
  )
}

const TestimonialCarousel = ({
  className,
  title,
  testimonials,
  visibleSlides,
}) => {
  return (
    <div className={className}>
      <CarouselProvider
        naturalSlideWidth={380}
        naturalSlideHeight={548}
        totalSlides={testimonials.length}
        visibleSlides={visibleSlides}
        isIntrinsicHeight={true}
      >
        <div className="max-w-7xl px-4 mx-auto">
          <div className="max-w-lg mx-auto mb-12 text-center">
            <IconQuote className="text-gold h-12 mx-auto mb-4" />
            <h2 className="my-2 text-3xl md:text-4xl font-bold font-display">
              {title}
            </h2>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6 items-stretch">
            <Slider>
              {testimonials.map(({ testimony, title, position }, index) => (
                <TestimonyItem
                  key={title}
                  testimony={testimony}
                  title={title}
                  position={position}
                  index={index}
                />
              ))}
            </Slider>
          </div>
          <div className="flex justify-center space-x-2 lg:hidden">
            <PaginationIndicator testimonials={testimonials} />
          </div>
        </div>
      </CarouselProvider>
    </div>
  )
}

const SectionTestimonials = () => {
  const data = useStaticQuery(graphql`
    {
      siteYaml(type: { eq: "home" }) {
        sectionTestimonials {
          title
          testimonials {
            testimony
            title
            position
          }
        }
      }
    }
  `)

  const { title, testimonials } = data.siteYaml.sectionTestimonials

  return (
    <section
      className="py-20 xl:bg-contain bg-top bg-no-repeat"
      style={{
        backgroundImage: "url('/backgrounds/intersect.svg')",
      }}
    >
      <TestimonialCarousel
        className="md:hidden"
        title={title}
        testimonials={testimonials}
        visibleSlides={1}
      />
      <TestimonialCarousel
        className="hidden md:block lg:hidden"
        title={title}
        testimonials={testimonials}
        visibleSlides={2}
      />
      <TestimonialCarousel
        className="hidden lg:block"
        title={title}
        testimonials={testimonials}
        visibleSlides={3}
      />
    </section>
  )
}

export default SectionTestimonials
