import { ArrowRightIcon } from "@heroicons/react/solid"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Icon } from "../../display"
import { Button } from "../../inputs"

const WorkItem = ({ description, title, image, action }) => {
  return (
    <div className="w-full md:w-1/2 xl:w-1/4 px-3 mb-6">
      <div className="p-12 h-full bg-white shadow rounded flex flex-col justify-between">
        <div>
          <div>
            <Icon name={image} className="w-full h-48 mx-auto my-4" />
          </div>
          <h3 className="mb-3 font-display font-bold text-xl text-jet">
            {title}
          </h3>
          <p className="text-sm text-jet-light leading-relaxed text-justify">
            {description}
          </p>
        </div>
        <div className="mt-8">
          <Button component="a" href={action.href} variant="text">
            <div className="flex flex-row items-center">
              <span className="inline-block whitespace-nowrap">
                {action.label}
              </span>
              <ArrowRightIcon className="w-4 h-4 ml-2" />
            </div>
          </Button>
        </div>
      </div>
    </div>
  )
}

const SectionOurWork = () => {
  const data = useStaticQuery(graphql`
    {
      siteYaml(type: { eq: "home" }) {
        sectionWhatWeDo {
          items {
            description
            title
            image
            action {
              href
              label
            }
          }
        }
      }
    }
  `)

  const { items } = data.siteYaml.sectionWhatWeDo

  return (
    <div className="py-20">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap -mx-3 -mb-6 text-center items-stretch">
          {items.map(({ description, title, image, action }) => (
            <WorkItem
              key={title}
              description={description}
              title={title}
              image={image}
              action={action}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

SectionOurWork.propTypes = {}

export default SectionOurWork
