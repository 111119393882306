import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import ImageIntroduction from "../../../images/svg/introduction.inline.svg"
import { Button } from "../../inputs"
import { ArrowRightIcon } from "@heroicons/react/solid"

const SectionHero = () => {
  const data = useStaticQuery(graphql`
    {
      siteYaml(type: { eq: "home" }) {
        sectionHero {
          textPrimary
          textSecondary
          actionPrimary {
            href
            label
          }
        }
      }
    }
  `)
  const { textPrimary, textSecondary, actionPrimary } =
    data.siteYaml.sectionHero

  return (
    <div className="container px-4 mx-auto">
      <div className="flex flex-wrap items-end -mx-3 h-full lg:py-24 xl:py-24">
        <div className="w-full lg:w-1/2 px-3">
          <div className="py-12">
            <div className="max-w-lg lg:max-w-md mx-auto lg:mx-0 mb-8 text-center lg:text-left">
              <h2 className="text-4xl md:text-6xl mb-4 font-extrabold text-jet">
                <span className="leading-normal font-display">
                  {textPrimary}
                </span>
              </h2>
              <p className="text-jet-light leading-relaxed md:text-2xl">
                {textSecondary}
              </p>
            </div>
            <div className="text-center lg:text-left mt-6 md:mt-16">
              <Button size="large" component={Link} to={actionPrimary.href}>
                <div className="flex flex-row items-center">
                  <span className="inline-block whitespace-nowrap">
                    {actionPrimary.label}
                  </span>
                  <ArrowRightIcon className="text-jet w-4 h-4 ml-3" />
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-3 mb-12 lg:mb-0">
          <div className="lg:h-128 flex items-center justify-center">
            <ImageIntroduction className="lg:max-w-lg" />
          </div>
        </div>
      </div>
    </div>
  )
}

SectionHero.propTypes = {}

export default SectionHero
