import React from "react"
import { Button } from "../../inputs"
import AitiIconLight from "../../../images/svg/icon-light.inline.svg"
import { graphql, Link, useStaticQuery } from "gatsby"

const SectionCareers = () => {
  const data = useStaticQuery(graphql`
    {
      siteYaml(type: { eq: "home" }) {
        sectionJoinTeam {
          title
          quote
          description
          action {
            href
            label
          }
        }
      }
    }
  `)
  const { title, description, action } = data.siteYaml.sectionJoinTeam

  return (
    <section className="bg-jet">
      <div className="container px-4 mx-auto">
        <div className="pt-16 pb-20 px-8 md:px-16">
          <div className="flex flex-wrap -mx-8">
            <div className="hidden md:block w-full md:w-1/3 px-2 mb-12 md:mb-0">
              <div className="relative max-w-sm sm:max-w-md mx-auto">
                <AitiIconLight className="w-full h-48 mx-auto" />
              </div>
            </div>
            <div className="w-full md:w-2/3 px-8 flex">
              <div className="my-auto">
                <h2 className="text-3xl md:text-4xl font-semibold text-silver font-display mb-6">
                  {title}
                </h2>
                <p className="mt-2 mb-6 text-silver-light leading-loose">
                  {description}
                </p>
                <Button
                  component={Link}
                  to={action.href}
                  variant="contained-gold-inverse"
                  size="large"
                >
                  {action.label}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionCareers
