import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Icon } from "../../display"
import { graphql, Link, useStaticQuery } from "gatsby"
import { ArrowRightIcon } from "@heroicons/react/solid"
import BlobTearImage from "../../../images/svg/blob-tear.inline.svg"

const ServiceItem = ({ icon, title, href }) => {
  return (
    <div className="py-4 px-4 block hover:bg-gold-lighter group rounded-xl transition duration-150">
      <Link to={href} className="flex items-center h-full w-full">
        <div className="flex items-center h-full w-full">
          <div className="p-4 rounded-xl bg-jet mr-5 text-gold group-hover:text-silver">
            <Icon name={icon} className="w-8 h-8" />
          </div>
          <div>
            <h3 className="mb-2 text-lg font-semibold">{title}</h3>
          </div>
        </div>
        <ArrowRightIcon className="w-8 h-8 ml-4" />
      </Link>
    </div>
  )
}

const SectionOurServices = () => {
  const data = useStaticQuery(graphql`
    {
      siteYaml(type: { eq: "home" }) {
        sectionOurServices {
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
          services {
            title
            icon
            href
          }
        }
      }
    }
  `)

  const { title, image, services } = data.siteYaml.sectionOurServices

  return (
    <section
      id="our-services"
      className="py-12 md:py-32 overflow-x-hidden bg-white"
    >
      <div className="container px-4 mx-auto">
        <div className="w-full mb-6 lg:mb-0">
          <h2 className="max-w-max mx-auto text-4xl mt-3 font-bold font-display">
            {title}
          </h2>
        </div>
        <div className="flex flex-wrap lg:flex-nowrap">
          <div className="w-full lg:w-1/2">
            <div className="py-6 lg:pr-20">
              <div className="flex flex-col items-stretch">
                {services.map(({ title, icon, href }) => (
                  <ServiceItem title={title} icon={icon} href={href} />
                ))}
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-full lg:w-1/2 my-12 lg:my-0">
            <div className="w-full h-full flex flex-col justify-end">
              <GatsbyImage
                image={getImage(image)}
                className="mx-auto w-full z-10 rounded-xl"
              />
            </div>
            <BlobTearImage className="hidden lg:block absolute bottom-0 left-0 w-50 -ml-12 mb-32 xl:mb-40 2xl:mb-52" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionOurServices
