import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import QuoteIcon from "../../../images/svg/quote.inline.svg"
import PatternDotImage from "../../../images/svg/pattern-dot.inline.svg"

const SectionAboutUs = () => {
  const data = useStaticQuery(graphql`
    {
      siteYaml(type: { eq: "home" }) {
        sectionAboutUs {
          title
          quote
          highlight
          description {
            text
            isHighlighted
          }
          image {
            childImageSharp {
              gatsbyImageData(width: 1200)
            }
          }
        }
      }
    }
  `)
  const { title, quote, highlight, description, image } =
    data.siteYaml.sectionAboutUs

  return (
    <section
      id="about-us"
      className="py-12 md:py-32 overflow-x-hidden bg-white"
    >
      <div className="container px-4 mx-auto">
        <div className="mb-20">
          <div className="mb-8">
            <h2 className="max-w-max text-4xl mt-3 font-bold font-display mx-auto">
              {title}
            </h2>
          </div>
          <div className="flex flex-col items-center">
            <div className="mb-8">
              <QuoteIcon className="w-20 h-20 text-gold -mb-16" />
              <div className="z-10 font-display max-w-xl w-full text-2xl text-center leading-loose">
                {quote}
              </div>
            </div>
            <div className="relative bg-jet text-silver px-16 py-12 leading-relaxed w-full max-w-3xl text-lg font-medium text-center rounded-xl">
              <div>{highlight}</div>
              <PatternDotImage className="absolute top-6 left-0 w-28 -ml-12" />
              <div className="absolute top-0 right-0 -mr-6 -mt-8 border-4 h-40 w-16 rounded-xl border-gold" />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap lg:flex-nowrap">
          <div className="w-full lg:w-2/5 py-6">
            <div className="pr-4 flex flex-col justify-center rounded text-justify">
              {description.map(({ text, isHighlighted }) => (
                <p
                  className={`${
                    isHighlighted ? "border-l-4 border-gold pl-4 py-4 pr-4" : ""
                  } mb-2`}
                >
                  {text}
                </p>
              ))}
            </div>
          </div>
          <div className="relative w-full lg:w-3/5 my-12 lg:my-0">
            <div className="w-full h-full flex flex-col justify-center">
              <GatsbyImage
                image={getImage(image)}
                className="mx-auto w-full z-10 rounded-xl"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionAboutUs
