import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionHero from "../components/sections/home/SectionHero"
import SectionOurWork from "../components/sections/home/SectionOurWork"
import SectionGetAitified from "../components/sections/SectionGetAitified"
import SectionCareers from "../components/sections/home/SectionCareers"
import SectionTestimonials from "../components/sections/home/SectionTestimonials"
import SectionOurServices from "../components/sections/home/SectionOurServices"
import SectionOurValues from "../components/sections/home/SectionOurValues"
import SectionAboutUs from "../components/sections/home/SectionAboutUs"
import SectionOurApproach from "../components/sections/home/SectionOurApproach"

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Aiti`
  const { seo, sectionCallForAction } = data.siteYaml

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={seo.title} description={seo.description} />
      <section className="bg-silver-light">
        <SectionHero />
        <SectionOurWork />
        <SectionAboutUs />
        <SectionOurApproach />
        <SectionOurValues />
        <SectionOurServices />
        <SectionTestimonials />
        <SectionGetAitified
          action={sectionCallForAction.action}
          title={sectionCallForAction.title}
          description={sectionCallForAction.description}
        />
        <SectionCareers />
      </section>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    siteYaml(type: { eq: "home" }) {
      seo {
        title
        description
      }
      sectionCallForAction {
        action {
          href
          label
        }
        title
        description
      }
    }
  }
`
