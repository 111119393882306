import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Icon } from "../../display"

const SectionOurValues = () => {
  const data = useStaticQuery(graphql`
    {
      siteYaml(type: { eq: "home" }) {
        sectionOurValues {
          title
          values {
            title
            description
            image
          }
        }
      }
    }
  `)
  const { title, values } = data.siteYaml.sectionOurValues

  const ValueCard = ({ title, description, image }) => {
    return (
      <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-6">
        <div className="h-full pt-8 px-8 pb-6 bg-white text-center rounded shadow">
          <Icon name={image} className="w-24 h-24 mx-auto mb-4 text-gold" />
          <h3 className="mb-2 font-bold font-display">{title}</h3>
          <p className="text-sm text-jet-light text-justify">{description}</p>
        </div>
      </div>
    )
  }

  return (
    <section className="pt-16 pb-20 ">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap items-center mb-12">
          <div className="w-full mb-6 lg:mb-0">
            <h2 className="max-w-max mx-auto text-4xl font-bold font-display">
              {title}
            </h2>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 -mb-6 items-stretch">
          {values.map(({ title, description, image }) => (
            <ValueCard
              key={title}
              title={title}
              description={description}
              image={image}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionOurValues
