import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Icon } from "../../display"

const ApproachCard = ({ title, description, image }) => {
  return (
    <div className="w-full lg:w-1/3 px-3 mb-6">
      <div className="h-full px-8 pb-8 pt-2 bg-white shadow rounded">
        <Icon name={image} className="h-48 w-48 mx-auto mb-2 mt-2" />
        <h3 className="mb-2 font-bold font-display">{title}</h3>
        <p className="text-sm text-jet-light leading-relaxed text-justify">
          {description}
        </p>
      </div>
    </div>
  )
}

const SectionOurApproach = () => {
  const data = useStaticQuery(graphql`
    {
      siteYaml(type: { eq: "home" }) {
        sectionOurApproach {
          title
          approaches {
            title
            description
            image
          }
        }
      }
    }
  `)
  const { title, approaches } = data.siteYaml.sectionOurApproach

  return (
    <section className="py-20 bg-jet">
      <div className="container px-4 mx-auto">
        <div className="w-full mb-16 text-white">
          <h2 className="max-w-max mx-auto text-3xl md:text-4xl font-bold font-display">
            {title}
          </h2>
        </div>
        <div className="flex flex-wrap -mx-3 -mb-6 text-center items-stretch">
          {approaches.map(({ title, description, image }, index) => (
            <ApproachCard
              key={title}
              index={index}
              title={title}
              description={description}
              image={image}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionOurApproach
