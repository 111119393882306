import React from "react"
import PropTypes from "prop-types"
import { Button } from "../inputs"
import AitiIconDark from "../../images/svg/icon-dark.inline.svg"
import { Link } from "gatsby"

const SectionGetAitified = ({ title, description, action }) => {
  return (
    <section className="py-16">
      <div className="container px-4 mx-auto">
        <div className="pt-16 pb-20 px-8 md:px-16 bg-gold rounded">
          <div className="flex flex-col-reverse md:flex-row flex-wrap -mx-8">
            <div className="w-full md:w-2/3 px-8 flex flex-col justify-around items-start">
              <h2 className="text-3xl md:text-4xl font-semibold text-jet font-display">
                {title}
              </h2>
              <p className="mt-2 mb-6 text-jet-light leading-loose">
                {description}
              </p>
              <Button
                component={Link}
                to={action.href}
                variant="contained-jet-inverse"
                size="large"
              >
                {action.label}
              </Button>
            </div>
            <div className="hidden md:block w-full md:w-1/3 px-2 mb-12 md:mb-0">
              <div className="relative max-w-sm sm:max-w-md mx-auto">
                <AitiIconDark className="w-full h-48 mx-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

SectionGetAitified.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }),
}

SectionGetAitified.defaultProps = {
  title: `Get Aitified!`,
}

export default SectionGetAitified
